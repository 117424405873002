// in src/users.js
import React, { FC } from 'react';
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  DateField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  TopToolbar,
  CreateButton,
  ExportButton,
  usePermissions,
  GetListResult,
} from 'react-admin';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { AltTextField, Empty } from '../Components';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Schedule New Meeting"/>
        <ExportButton/>
    </TopToolbar>
);

const MeetingList: FC = (props) => {
  const { permissions } = usePermissions();
  return (
    <List 
      {...props} 
      actions={<ListActions/>} 
      empty={<Empty buttonLabel="Add Meeting Link"/>} 
      queryOptions={{
        refetchInterval: (data: typeof GetListResult) => {
          const statuses = data?.data.map(({status} : {status: string}) => status);
          return (
            statuses?.includes('Uploaded') ||
            statuses?.includes('Processing...') 
            ? 20000 : false
          )
        },
      }}
    >
{/*
      <Datagrid rowClick="edit">
*/}
      <Datagrid>
      <TextField source="id" />
      <TextField source="unique_id" label="Meeting Title"/>
      <DateField source="date_time" label="Date, Time" showTime />
      <TextField source="status" />
      {/*<TextField source="name" />*/}
      <ReferenceField
        resource="meetings"
        source="report_id"
        reference="reports"
        link="show"
      >
        <ChipField source="filename" label="label" icon={<TrendingUpIcon/>} />
      </ReferenceField>
      {permissions === 'admin' && 
        <ReferenceField
          resource="meetings"
          source="owner_id"
          reference="users"
          link="edit"
        >
          <AltTextField sources={["full_name", "email"]} />
        </ReferenceField>
      }
{/*
      <EditButton />
*/}
      </Datagrid>
    </List>
  )
};

export default MeetingList;
