import React, { FC } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { Title, useDataProvider, usePermissions, useRedirect } from 'react-admin';

export const Settings: FC = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const redirectToMSOAuth = async () => {
    const { data, isLoading, error } = await dataProvider.getRecallOAuthMSUrl();
    const { url } = data;
    redirect(url);
  }
  const redirectToZoomOAuth = async () => {
    const { data, isLoading, error } = await dataProvider.getRecallOAuthZoomUrl();
    const { url } = data;
    redirect(url);
  }
  return (
    <Card>
      <Title title="Settings" />
      <CardContent>
        <Typography variant="h4">
        Settings
        </Typography>
        <Button 
          variant="contained"
          onClick={() => { redirectToZoomOAuth(); }}
        >Connect Zoom Account</Button>
        {permissions === 'admin' &&
          <Button 
            variant="contained"
            onClick={() => { redirectToMSOAuth(); }}
          >Connect MS Calendar</Button>
        }
      </CardContent>
    </Card>
  )
};

