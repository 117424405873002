import * as React from 'react';
import { Menu as RAMenu, useResourceDefinitions } from 'react-admin';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';

const Menu = () => {
    const resources = useResourceDefinitions();
    return (
        <RAMenu>
            {Object.keys(resources).map(name => {
                if (resources[name].hasList) {
                    return (<RAMenu.ResourceItem key={name} name={name} />)
                }
            })}
            <RAMenu.Item to="./book-coaching" primaryText="Book Coaching" leftIcon={<PeopleIcon />} />
            <RAMenu.Item to="./help" primaryText="Help" leftIcon={<HelpIcon />} />
            <RAMenu.Item to="./settings" primaryText="Settings" leftIcon={<SettingsIcon />} />
        </RAMenu>
    );
};
export default Menu;
