import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import simpleRestProvider from 'ra-data-simple-rest';

const httpClient = (url: any, options: any) => {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const apiUrl = '/api/v1';
const baseDataProvider = simpleRestProvider(apiUrl, httpClient);

export const dataProvider = {
    ...baseDataProvider, // Preserve methods that are not extended

    getPresignedUrl: (method: string, fileName: string, meetingId?: number) => {
      const query:any = {
        file_name: fileName,
      };
      if (typeof meetingId !== 'undefined') {
        query['meeting_id'] = meetingId;
      }
      return httpClient(`${apiUrl}/utils/presigned-${method}-url?${stringify(query)}`, {}).then(({ json }:any) => ({
        data: json,
      }));
    },
    createMeetingFile: (fileName: string, meetingId: number) => {
      const query:any = {
        file_name: fileName,
        meeting_id: meetingId,
      };
      return httpClient(`${apiUrl}/meeting_files/with_filename?${stringify(query)}`, {method: 'POST'}).then(({ json }:any) => ({
        data: json,
      }));
    },
    analyseMeeting: (meetingId: number) => {
      return httpClient(`${apiUrl}/meetings/analyse/${meetingId}`, {method: 'GET'}).then(({ json }:any) => ({
        data: json,
      }));
    },
    getRecallOAuthZoomUrl: () => {
      return httpClient(`${apiUrl}/recall/oauth/zoom`, {method: 'GET'}).then(({ json }:any) => ({
        data: json,
      }));
    },
    recallOAuthZoomCallback: (code: string) => {
      const query:any = {
        code: code,
      };
      return httpClient(`${apiUrl}/recall/oauth/zoom/callback?${stringify(query)}`, {method: 'GET'}).then();
    },
    getRecallOAuthMSUrl: () => {
      return httpClient(`${apiUrl}/recall/oauth/ms`, {method: 'GET'}).then(({ json }:any) => ({
        data: json,
      }));
    },
    recallOAuthMSCallback: (code: string, state: string) => {
      const query:any = {
        code: code,
        state: state,
      };
      return httpClient(`${apiUrl}/recall/oauth/ms/callback?${stringify(query)}`, {method: 'GET'}).then();
    },
}

export default dataProvider;
