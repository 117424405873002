import EventIcon from '@mui/icons-material/Event';

// import MeetingEdit from './MeetingEdit';
import MeetingList from './MeetingList';
// import MeetingCreate from './MeetingCreate';
import RecallCreate from '../Recall/RecallCreate';

export default {
  // create: MeetingCreate,
  // edit: MeetingEdit,
  create: RecallCreate,
  list: MeetingList,
  icon: EventIcon
};

