import React, { FC } from 'react';
import { Route } from "react-router-dom";
import { Admin as ReactAdmin, Resource, CustomRoutes, ListGuesser } from 'react-admin';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

import Users from './Users';
import Meetings from './Meetings';
import Recall from './Recall';
import Reports from './Reports';
import { BookCoaching, Help, HelpZoomSetup, Privacy, Settings, Terms, OAuthMSCallback, OAuthZoomCallback } from './views';
import Theme, { Footer } from './Theme';
import auth, { Login, PasswordRecovery, PasswordRecoverySent, ResetPassword } from './auth';

export const Admin: FC = () => {
  return (
    <React.Fragment>
      <ReactAdmin 
        {...auth}
        {...Theme}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        {(permissions: 'admin' | 'user') => [
          permissions === 'admin' ? (
            <Resource name="users" {...Users} />
          ) : null ,
          // permissions === 'admin' ? (
            // <Resource name="meetings" options={{ label: 'Legacy Meetings' }} {...Meetings} />
          // ) : null ,
            // <Resource name="recall" options={{ label: 'Meetings' }} {...Recall}/>,
            <Resource name="meetings" {...Meetings} />,
            <Resource name="reports" {...Reports} />,
            <CustomRoutes>
              <Route path="/book-coaching" element={<BookCoaching />} >
                <Route path="/book-coaching/:report_id" element={<BookCoaching />} />
              </Route>
              <Route path="/help" element={<Help />} />
              <Route path="/help/zoom-setup" element={<HelpZoomSetup />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/oauth/ms/callback/" element={<OAuthMSCallback />} />
              <Route path="/oauth/zoom/callback/" element={<OAuthZoomCallback />} />
            </CustomRoutes>,
            <Resource name="meeting_files" />,
            <Resource name="participants" />,
            <Resource name="recall" />,
          permissions === 'admin' ? null : (
            <Resource name="users" />
          ),
          // permissions === 'admin' ? null : (
          //   <Resource name="meetings" />
          // ),
        ]}
          <CustomRoutes noLayout >
            <Route path="/reset-password" element={<Login><ResetPassword /></Login>} />
            <Route path="/password-recovery" element={<Login><PasswordRecovery /></Login>} />
            <Route path="/password-recovery-sent" element={<Login><PasswordRecoverySent /></Login>} />
          </CustomRoutes>,
      </ReactAdmin>
    </React.Fragment>
  );
};
