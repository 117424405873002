import React, { FC } from 'react';
import { Card } from '@mui/material';
import { useDataProvider, useRedirect } from 'react-admin';
import { useSearchParams } from "react-router-dom";

export const OAuthMSCallback: FC = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code")
  const state = searchParams.get("state")
  const { data, isLoading, error } = dataProvider.recallOAuthMSCallback(code, state).then(() => (redirect('/')));
  return (<Card></Card>)
};

export const OAuthZoomCallback: FC = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code")
  const { data, isLoading, error } = dataProvider.recallOAuthZoomCallback(code).then(() => (redirect('/')));
  return (<Card></Card>)
};

